import './Footer.css';
import React from 'react';
export function Footer() {



    return (


        <div className="footer"><a className='footer' href='https://github.com/GaillardTom'><h1>Powered by GaillardTom</h1><img alt="GaillardTom" className="footer-image" width={50} height={50} src="/gh3d.png"/></a></div>
    );
};